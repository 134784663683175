<template>
  <div class="row" v-if="entity">
    <div class="col col-md-8">
      <div class="column">
        <base-add-button :label="$t('notes.add_note')" color="reseau"
          @click="isFormOpen = true" v-if="!isFormOpen" />

        <q-card flat bordered class="q-pa-sm" v-if="isFormOpen">
          <q-card-section>
            <NoteForm @reset="isFormOpen = false" @success="fetchNotes"
              model_type="entity" :model_id="entity.id" color="reseau" />
          </q-card-section>
        </q-card>

        <NotesTimeline :notes="notes" color="reseau"
          model_type="entity" :model_id="entity.id"
          @updated="fetchNotes" @delete="deleteNote" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { NoteForm, NotesTimeline } from '../../components/notes'

export default {
  name: 'EntityNotes',
  components: { NoteForm, NotesTimeline },
  props: {
    'form_model': { type: String, default: 'notes' }
  },

  data () {
    return {
      isFormOpen: false
    }
  },

  computed: {
    ...mapGetters({
      entity: 'entities/getCurrentEntity',
      getNotesFor: 'notes/getNotesFor'
    }),

    notes () {
      return this.getNotesFor('entity', this.entity.id)
    },

    model_id () {
      return this.$route.params.id ? Number(this.$route.params.id) : null
    }
  },

  mounted () {
    this.fetchNotes()
  },

  methods: {
    fetchNotes () {
      this.isFormOpen = false

      return this.$store.dispatch('notes/fetchNotes', {
        model_type: 'entity',
        model_id: this.model_id,
        rowsPerPage: 0
      }).finally(() => { this.$q.loading.hide() })
    },

    deleteNote (id) {
      this.$confirm(this.$t('notes.delete_note_confirm'), this.$t('form.delete')).onOk(() => {
        this.$store.dispatch('notes/deleteNote', id).then(() => {
          this.$q.notify({ message: this.$t('notes.delete_success'), color: 'positive' })
        }).catch(this.$notifyError)
          .finally(this.fetchNotes)
      })
    }
  }
}
</script>

<style>
</style>
