<template>
  <base-timeline :items="timelineEntries" :color="color" date_field="created_at">
    <template slot-scope="entry">
      <NoteCard :note="entry.item" :color="color" :editable="entry.item.id === selectedNote"
        :model_type="model_type" :model_id ="model_id" @menuClick="onMenuClick"
        @focus="onNoteFocus" @reset="selectedNote = null" @updated="selectedNote = null; $emit('updated')" />
    </template>
  </base-timeline>
</template>

<script>
import { mapGetters } from 'vuex'
import { NoteCard } from './'

export default {
  name: 'NotesTimeline',
  props: {
    notes: { type: Array, default: () => ([]) },
    color: { type: String },
    model_type: { type: String },
    model_id: { type: Number }
  },
  components: { NoteCard },

  computed: {
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      currentUser: 'auth/getCurrentUser'
    }),

    timelineEntries () {
      return this.notes.map(note => ({
        ...note,
        actions: [
          { label: this.$t('notes.delete_note'), payload: { name: 'note-delete', params: { id: note.id } } }
        ],
        is_favorite: !!this.favorites && !!this.favorites['note'] &&
          this.favorites['note'].some(favorite => favorite.id === note.id),
        model: { model_type: 'note', model_id: note.id }
      }))
    }
  },

  data () {
    return {
      selectedNote: null
    }
  },

  methods: {
    onNoteFocus (id) {
      this.selectedNote = id
    },

    onMenuClick ({ name, params }) {
      if (name === 'note-delete') {
        this.$emit('delete', params.id)
      } else {
        this.$router.push({ name, params })
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
</style>
