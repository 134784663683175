<template>
  <base-card v-bind="note" v-on="$listeners">
    <q-item class="q-pa-sm">
      <q-item-section>
        <div class="row q-pa-sm justify-between">
          <q-item-label caption>{{$formatDate(note.created_at)}}</q-item-label>
          <q-item-label caption>{{note.auteur.display_name}}</q-item-label>
        </div>

        <div class="col q-pa-sm">
          <NoteForm :note="note" :color="color" v-if="isEditable"
            :model_type="model_type" :model_id="model_id"
            @reset="$emit('reset')" @success="$emit('updated', note.id)" />

          <base-input-text :value="note.contenu" type="textarea" @focus="$emit('focus', note.id)" v-else />
        </div>
      </q-item-section>

      <q-item-section v-if="isFormOpen" class="row justify-end">
        <div class="col col-auto q-gutter-sm">
          <q-btn :color="color" size="sm" outline @click.native="resetForm()">{{$t('form.cancel')}}</q-btn>
          <q-btn :color="color" size="sm" unelevated type="submit">{{$t('form.save')}}</q-btn>
        </div>
      </q-item-section>
    </q-item>
  </base-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { NoteForm } from './'

export default {
  name: 'NoteCard',
  props: ['note', 'menuOptions', 'color', 'model_type', 'model_id', 'editable'],
  components: { NoteForm },

  data () {
    return {
      isFormOpen: false
    }
  },

  computed: {
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      currentUser: 'auth/getCurrentUser'
    }),

    isEditable () {
      return this.editable && (this.isAdmin || this.currentUser.id === this.note.auteur.id)
    }
  }
}
</script>

<style>
</style>
