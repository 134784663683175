<template>
  <form v-if="formFields" @submit.prevent.stop="save" class="fit">
    <base-input-text v-bind="formInputProps('contenu')" :color="color"
      v-model="formData.contenu" ref="contenu" />

    <div class="row justify-end">
      <div class="col col-auto q-gutter-sm">
        <q-btn :color="color" size="sm" outline @click.native="reset">{{$t('form.cancel')}}</q-btn>
        <q-btn :color="color" size="sm" unelevated type="submit" :disabled="!hasChanged">{{$t('form.save')}}</q-btn>
      </div>
    </div>
  </form>
</template>

<script>
import { BaseForm } from '../../mixins'

export default {
  name: 'NoteForm',
  mixins: [ BaseForm ],
  props: {
    'form_model': { type: String, default: 'notes' },
    'action': { type: String, default: 'notes/saveNote' },
    'color': { type: String, default: 'primary' },
    'model_type': { type: String, required: true },
    'model_id': { type: Number, required: true },
    'note': { type: Object, default: () => ({ id: null, contenu: '' }) }
  },

  watch: {
    formFields: {
      handler (newVal, oldVal) {
        if (newVal) {
          this.formData = {
            ...this.formData,
            ...newVal.map(({ id, options }) => ({ id, options }))
              .reduce((acc, { id, options }) => ({
                ...acc, [id]: options ? options.find(({ value }) => value === this.note[id]) : this.note[id]
              }), {}),
            model_type: this.model_type,
            model_id: this.model_id,
            titre: 'Note'
          }
        }
      },
      immediate: true
    }
  },

  mounted () {
    if (!this.formFields) this.$store.dispatch('notes/fetchFormFields')
    if (this.note.id) this.formData['id'] = this.note.id
  }
}
</script>

<style lang="stylus" scoped>
</style>
<!--  -->
